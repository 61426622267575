import React from 'react';

import { GatsbyImage } from 'gatsby-plugin-image';
import { useStaticQuery, graphql } from 'gatsby';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation, Autoplay, Pagination } from 'swiper';
import './testimonial.style.css';

import 'swiper/swiper-bundle.css';

SwiperCore.use([Navigation, Autoplay, Pagination]);

const Testimonials = () => {
  const testimonialData = useStaticQuery(graphql`
    {
      allContentfulTestimonial {
        nodes {
          clientName
          clientWorkDescription
          testimonial {
            testimonial
          }
          clientImage {
            gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED, width: 40, height: 40)
          }
        }
      }
    }
  `);

  const clientReviews = testimonialData.allContentfulTestimonial.nodes;

  return (
    <section className="flex flex-wrap py-xl mb-2 relative font-futura">
      <div className="px-12px md:px-0 flex-wrap w-full text-center !mb-10 wow" data-wow-offset="50" data-wow-delay=".8s">
        <div className="text-left md:text-center text-primary block text-md tracking-1 uppercase w-full mb-md">Don’t just take our word for it</div>
        <div className="text-xl text-left md:text-center mx-auto font-semibold block">
          <p className="md:text-36px md:leading-1.3 l:text-xl ">Take it from clients that trust our work</p>
          <p className="md:text-36px md:leading-1.3 l:text-xl">and commitment to deliver.</p>
        </div>
      </div>
      <div className="testimonials-slider mx-auto flex flex-wrap mt-n40 relative overflow-hidden wow w-full" data-wow-offset="50" data-wow-delay=".8s">
        <Swiper
          className="swiper-wrapper"
          pagination={{ clickable: true }}
          breakpoints={{
            375: {
              slidesPerView: 1,
              spaceBetween: 10,
            },
            640: {
              slidesPerView: 1.5,
              spaceBetween: 20,
            },
            768: {
              slidesPerView: 1.5,
              spaceBetween: 10,
            },
            1024: {
              slidesPerView: 1.5,
              spaceBetween: 20,
            },
          }}
          autoplay={{
            delay: 5000,
            disableOnInteraction: false,
          }}
        >
          {clientReviews.map(({
            clientName,
            clientImage,
            testimonial,
            clientWorkDescription,
          }) => (
            <SwiperSlide key={clientName} className="swiper-slider">
              <div className="testimonial block mt-5 lg:m-7 md:m-7 md:pb-12px w-full p-4">
                <blockquote className="py-lg px-md mb-6 relative w-full leading-small float-left shadow-md rounded-md font-light after:content after:w-0 after:h-0 after:border-solid after:absolute after:left-70px after:bottom-10px after:border-t-secondary after:border-r-transparent after:border-b-transparent after:border-l-transparent after:border-t-10px after:border-r-10px after:border-b-0 after:border-l-10px">{testimonial.testimonial}</blockquote>
                <figure className="w-full">
                  <GatsbyImage className="float-left mr-4 w-5 h-5 rounded-full" image={clientImage.gatsbyImageData} alt="review" />
                  <figcaption className="inline-block">
                    <div className="mt-3 mb-none font">{clientName}</div>
                    <small className="font-light opacity-60">{clientWorkDescription}</small>
                  </figcaption>
                </figure>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </section>
  );
};

export default Testimonials;
